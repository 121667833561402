import { makeStyles } from 'tss-react/mui';
import React from 'react';
import { useTranslation } from 'i18n';
import { useSelector } from 'react-redux';
import { Theme, Typography, useMediaQuery } from '@mui/material';
import { AuthSelectors } from 'store/selectors';
import CloseButton from 'components/UserProfileQuestionsPopovers/CloseButton';
import Google16px from 'components/svg/Google16px.svg';
import Button from 'components/common/Button';
import Email16px from 'components/svg/Email16px.svg';
import { trackButtonClick } from '../screens/ButtonClickTracker';

const useStyles = makeStyles()((theme) => ({
  root: {
    height: 350,
    width: 302,
    padding: '56px 40px 0 40px',
    borderRadius: 16,
    background: '#3753D7',
    backgroundImage: 'url(/static/svg/NudgeToLoginBg.svg)',
    backgroundPosition: '-8px 0',
    [theme.breakpoints.down('sm')]: {
      backgroundImage: 'url(/static/svg/NudgeToLoginBgMobile.svg)',
      width: 268,
      height: 310,
      padding: '48px 40px 0 40px',
    },
  },
  item: {
    lineHeight: 22,
  },
  buttonLetsGo: {
    '&.MuiButton-root': {
      padding: '16px 33px',
      whiteSpace: 'nowrap',
      background: '#fff',
      borderRadius: 20,
      width: 222,
      height: 40,
      fontSize: 13,
      fontWeight: 500,
      textWrap: 'nowrap',
      justifyContent: 'center',
      [theme.breakpoints.down('sm')]: {
        fontSize: 11,
      },
    },
  },
  buttonMaybeLater: {
    '&.MuiButton-root': {
      color: '#fff',
      width: 222,
      height: 16,
      fontSize: 13,
      fontWeight: 500,
      padding: 0,
      [theme.breakpoints.down('sm')]: {
        fontSize: 11,
      },
    },
  },
  buttonMaybeLaterWrapper: {
    paddingTop: 24,
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
    [theme.breakpoints.down('sm')]: {
      paddingTop: 20,
    },
  },
  textWrapper: {
    width: '100%',
    textAlign: 'center',
    paddingBottom: 0,
    height: 36,
    marginBottom: 24,
    [theme.breakpoints.down('sm')]: {
      marginBottom: 20,
    },
  },
  titleWrapper: {
    width: '100%',
    textAlign: 'center',
    height: 22,
    marginBottom: 24,
    [theme.breakpoints.down('sm')]: {
      marginBottom: 17,
    },
  },
  closeBtn: {
    color: '#FFF',
    top: 12,
    right: 18,
    left: 'unset',
  },
}));

interface IProps {
  onClose: any;
  loginEmail: any;
  loginGoogle: any;
  getStarted: any;
}

const NudgeToLoginPopoverContainer: React.FC<IProps> = (props) => {
  const { onClose, loginEmail, loginGoogle, getStarted } = props;
  const { classes, cx } = useStyles();
  const [t] = useTranslation('public');
  const currentUser = useSelector(AuthSelectors.currentUser);
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));

  return (
    <div className={classes.root}>
      <CloseButton className={classes.closeBtn} onClose={onClose} />
      <div className={cx(classes.item, classes.titleWrapper)}>
        <Typography style={{ lineHeight: '20px' }} variant="h6" color="white">
          {t('nudgeToLoginPopup.letsSingIn', { name: currentUser?.first_name })}
        </Typography>
      </div>
      <div className={cx(classes.item, classes.textWrapper)}>
        <Typography
          style={{ lineHeight: '20px', ...(isMobile && { fontWeight: 500 }) }}
          variant={isMobile ? 'body2' : 'subtitle2'}
          color="white"
        >
          {t('nudgeToLoginPopup.letsSingInDescription')}
        </Typography>
      </div>
      <div style={{ width: '100%' }}>
        <div style={{ display: 'flex', width: '100%', justifyContent: 'center' }}>
          <Button
            className={classes.buttonLetsGo}
            variant="outlined"
            startIcon={<Email16px />}
            onClick={() => {
              onClose();
              trackButtonClick('login-nudge-email');
              loginEmail();
            }}
          >
            {t('auth.loginForm.loginEmail')}
          </Button>
        </div>
        <div style={{ paddingTop: 12, display: 'flex', width: '100%', justifyContent: 'center' }}>
          <Button
            className={classes.buttonLetsGo}
            variant="outlined"
            onClick={() => {
              onClose();
              trackButtonClick('login-nudge-google');
              loginGoogle();
            }}
            startIcon={<Google16px />}
          >
            {t('auth.loginForm.loginGoogle')}
          </Button>
        </div>
        <div className={classes.buttonMaybeLaterWrapper}>
          <Button
            className={classes.buttonMaybeLater}
            onClick={() => {
              onClose();
              getStarted();
            }}
          >
            {t('auth.joinForm.submit')}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default NudgeToLoginPopoverContainer;
